.border-none {
    border-collapse: collapse;
    border: none;
  }
  
  .border-none th {
    border: 2px solid black;
    padding: 20px;
  }

  .border-none tr:first-child th {
    border-top: none;
  }
  
  .border-none tr:last-child th {
    border-bottom: none;
  }
  
  .border-none tr th:first-child {
    border-left: none;
  }
  
  .border-none tr th:last-child {
    border-right: none;
  }
  
  .border-none td {
    border: 2px solid black;
    padding: 20px;
  }
  
  /* .border-none tr:first-child td {
    border-top: none;
  } */
  
  .border-none tr:last-child td {
    border-bottom: none;
  }
  
  .border-none tr td:first-child {
    border-left: none;
  }
  
  .border-none tr td:last-child {
    border-right: none;
  }