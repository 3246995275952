@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&family=Ubuntu:wght@300;400;500&display=swap');

/* body {
  background: #0f3139;
} */

.reveal .progress {
  /* background: rgba(0, 0, 0, 0.2); */
  /* color: #edc54d; */
}

.reveal h1,
.reveal h2,
.reveal h3 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
}
 
/* .reveal .present {
  min-height: 100%;
  width: 128mm;
  height: 96mm; 
} */

.reveal .controls {
  /* color: #edc54d; */
  font-size: 20px;
  translate: 0px -80px
}

p,
li {
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: 'Open Sans', sans-serif;

  font-size: 36px;
  line-height: 1.5;
  margin: 10px 0;
}

li::marker {
  /* content: counter(list-item) "› "; */
  /* color: #21685c; */
}

.App {
  box-sizing: border-box; /* Opera/IE 8+ */
}

.reveal a {
  /* color: white; */
  text-decoration: underline;
  font-style: italic;
}

.reveal pre {
  width: unset;
  padding-left: 20px;
}

/* Ensure certain elements are never larger than the slide itself */
.reveal img,
.reveal video,
.reveal iframe {
  max-width: 100%;
  max-height: 100%;
}

aside {
  margin-top: 75px;
  font-style: italic;
  font-size: 28px;
}
