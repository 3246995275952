html {
  height: 100%
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.sidebar {
  background-image: linear-gradient(180deg, #276e42 50%, #D9EAE3 70%);
  color: white;
}

div.content {
  height: 100%;
}


.navbar-toggler {
  background-color: rgba(255, 255, 255, 0.1);
  margin: 4px;
}

.nav-link {
  color: #d7d7d7;
}

.nav-item {
  height: 75px;
  /* max-width: 250px; */
  text-align: center;
  font-size: 0.9rem;
  color: #d7d7d7;
  margin: 5px 10px;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
}

.nav-item:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.simple-nav {
  text-decoration: none;
  transition: all .2s ease-in-out;
}

.simple-nav:hover { 
  transform: scale(1.1); 
}

.health-cards {
  background-color: #276e42;
  color: white;
  font-style: normal;
  text-decoration: none;
  padding: 25px;
  margin: 25px;
  min-width: 175px;
}

.grow:hover {
  transform: scale(1.1); 
}

.u-list li {
  font-size: 18px;
}

.figure-caption {
  color: #262626;
  font-style: normal;
}

.fade-in-image { animation: fadeIn 1s; }

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

