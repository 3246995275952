#q-graph {
    display: block; /* fixes layout wonkiness in FF1.5 */
    position: relative; 
    width: 600px; 
    height: 300px;
    margin: 1.1em 0 0; 
    padding: 0;
    background: transparent;
    font-size: 11px;
  }
  
  #q-graph caption {
    caption-side: top; 
    width: 600px;
    text-transform: uppercase;
    letter-spacing: .5px;
    top: -40px;
    position: relative; 
    z-index: 10;
    font-weight: bold;
  }
  
  #q-graph tr, #q-graph th, #q-graph td { 
    position: absolute;
    bottom: 0; 
    width: 150px; 
    z-index: 2;
    margin: 0; 
    padding: 0;
    text-align: center;
    color: white;
  }
  
  #q-graph td {
    transition: all .3s ease;
    
    &:hover {
      background-color: desaturate(#85144b, 100);
      opacity: .9;
      color: white;
    }
  }
    
  #q-graph thead tr {
    left: 100%; 
    top: 50%; 
    bottom: auto;
    margin: -2.5em 0 0 5em;}
  #q-graph thead th {
    width: 7.5em; 
    height: auto; 
    padding: 0.5em 1em;
  }
  #q-graph thead th.sent {
    top: 0; 
    left: 0; 
    line-height: 2;
  }
  #q-graph thead th.paid {
    top: 2.75em; 
    line-height: 2;
    left: 0; 
  }
  
  #q-graph tbody tr {
    height: 296px;
    padding-top: 2px;
    border-right: 1px dotted #C4C4C4; 
    color: rgb(201, 181, 67);
  }
  #q-graph #q1 {
    left: 0;
  }
  #q-graph #q2 {left: 150px;}
  #q-graph #q3 {left: 300px;}
  #q-graph #q4 {left: 450px; border-right: none;}
  #q-graph tbody th {bottom: -1.75em; vertical-align: top;
  font-weight: normal; color: white;}
  #q-graph .bar {
    width: 60px; 
    border: 1px solid; 
    border-bottom: none; 
    color: #000;
  }
  #q-graph .bar p {
    margin: 5px 0 0; 
    padding: 0;
    opacity: .4;
  }
  #q-graph .pg {
    left: 45px; 
    background-color: rgb(201, 181, 67);
    border-color: transparent;
  }
  #q-graph .pl {
    left: 45px; 
    background-color: rgb(46, 168, 184);
    border-color: transparent;
  }
  
  #q-graph .ev {
    left: 45px; 
    background-color: rgb(43, 100, 10);
    border-color: transparent;
  }
  
  #ticks {
    position: relative; 
    top: -300px; 
    left: 2px;
    width: 596px; 
    height: 300px; 
    z-index: 1;
    margin-bottom: -300px;
    font-size: 10px;
    font-family: "fira-sans-2", Verdana, sans-serif;
  }
  
  #ticks .tick {
    position: relative; 
    border-bottom: 1px dotted #C4C4C4; 
    width: 600px;
  }
  
  #ticks .tick p {
    position: absolute; 
    left: -5em; 
    top: -0.8em; 
    margin: 0 0 0 0.5em;
  }