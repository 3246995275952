#text {
  animation: fill 0.5s ease forwards 4s;
}

#text path:nth-child(1) {
  stroke-dasharray: 567px;
  stroke-dashoffset: 567px;
  animation: line-anim 2s ease forwards;
}

#text path:nth-child(2) {
  stroke-dasharray: 550px;
  stroke-dashoffset: 550px;
  animation: line-anim 2s ease forwards 0.3s;
}

#text path:nth-child(3) {
  stroke-dasharray: 361px;
  stroke-dashoffset: 361px;
  animation: line-anim 2s ease forwards 0.6s;
}

#text path:nth-child(4) {
  stroke-dasharray: 361px;
  stroke-dashoffset: 361px;
  animation: line-anim 2s ease forwards 0.9s;
}

#text path:nth-child(5) {
  stroke-dasharray: 544px;
  stroke-dashoffset: 544px;
  animation: line-anim 2s ease forwards 1.2s;
}

#text path:nth-child(6) {
  stroke-dasharray: 798px;
  stroke-dashoffset: 798px;
  animation: line-anim 2s ease forwards 1.5s;
}

#text path:nth-child(7) {
  stroke-dasharray: 544px;
  stroke-dashoffset: 544px;
  animation: line-anim 2s ease forwards 1.8s;
}

#text path:nth-child(8) {
  stroke-dasharray: 571px;
  stroke-dashoffset: 571px;
  animation: line-anim 2s ease forwards 2.1s;
}

#text path:nth-child(9) {
  stroke-dasharray: 361px;
  stroke-dashoffset: 361px;
  animation: line-anim 2s ease forwards 2.4s;
}

#text path:nth-child(10) {
  stroke-dasharray: 557px;
  stroke-dashoffset: 557px;
  animation: line-anim 2s ease forwards 2.7s;
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
